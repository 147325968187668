@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
    ZDS component style overrides since tailwind styles are of higher specificity.
    Note: Will need to update the background-color property when the ZDS theme is updated.
*/
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained,
[type="button"].MuiButtonBase-root.MuiButton-root.MuiButton-contained,
[type="reset"].MuiButtonBase-root.MuiButton-root.MuiButton-contained,
[type="submit"].MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background-color: #0d4ac3;
}

button.MuiButtonBase-root.MuiButton-root.Mui-disabled,
[type="button"].MuiButtonBase-root.MuiButton-root.Mui-disabled,
[type="reset"].MuiButtonBase-root.MuiButton-root.Mui-disabled,
[type="submit"].MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}
